//=================================================
// Page title
//=================================================

#page-title {
  &.with-img {
    // background-attachment: fixed;

    position: relative;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
    height: 100vh;
    min-height: 550px;
    max-height: 860px;

    .wrapper {
      height: 100%;
    }

    // Color
    color: $body_text_tertiary_color;

    [class*="widget-text-editor"] {
      color: $body_text_tertiary_color;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    a,
    abbr,
    address,
    cit,
    code,
    del,
    dfn,
    em,
    ins,
    q,
    samp,
    small,
    strong,
    sub,
    sup,
    b,
    i,
    hr,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    fieldset,
    legend,
    label,
    span,
    caption,
    figcaption,
    details,
    hgroup,
    summary,
    mark,
    audio,
    video {
      color: inherit;
    }

    .btn {
      color: inherit;

      &.btn-before-dot {
        &:before {
          color: inherit;
        }
      }

      &.btn-after-dot {
        &:after {
          color: inherit;
        }
      }
    }

    a,
    .btn {
      color: inherit;

      @extend .transition-opacity;

      &:hover {
        opacity: 0.5;
      }
    }
  }
}

#page-title {
  .wrapper {
    position: relative;
    z-index: 1;
  }
}

.page-title-bg-color {
  background-color: $body_main_element_secondary_color;
  position: absolute;
  opacity: 0.5;
  width: 100%;
  height: 100%;
  top: 0;
}
