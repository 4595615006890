//=================================================
// Buttons
//=================================================

.btn {
    min-width: 12.105rem;

    &.btn-sm {
        min-width: 6.158rem;
    }

    &.btn-lg {
        min-width: 13.632rem;
    }

    &.btn-regular {
        font-family: $font_primary;
        font-weight: 400;
    }

    &.btn-bold {
        font-family: $font_primary_bold;
        font-weight: 700;
    }

    &.before {
        @include lhCrop-b(1.23, 0.75);
    }

    &.after {
        @include lhCrop-a(1.23, 0.83);
    }

    &.btn-link {
        font-family: $font_secondary_medium;
        font-weight: 500;
        color: $body_links_color;
        text-transform: uppercase;

        &:hover {
            color: $body_links_hover_color;
        }

        &.link-no-space {
            line-height: 0.90;
        }
    }

    &.btn-before-dot {
        &:before {
            content: "\2022";
            color: $body_links_color;
            width: 1rem;
            font-size: 0.6rem;
            padding-right: 0.526rem;
            transition: color $transition-s ease;
        }

        &:hover {
            &:before {
                color: $body_links_hover_color;
            }
        }
    }

    &.btn-after-dot {
        &:after {
            content: "\2022";
            color: $body_links_color;
            width: 1rem;
            font-size: 0.6rem;
            padding-left: 0.526rem;
            transition: color $transition-s ease;
        }

        &:hover {
            &:after {
                color: $body_links_hover_color;
            }
        }
    }

    &.btn-lg {
        &.btn-before-dot {
            &:before {
                position: relative;
                top: -0.158rem;
            }
        }

        &.btn-after-dot {
            &:after {
                position: relative;
                top: -0.158rem;
            }
        }
    }

    &-outline-tertiary {
        color: $body_text_tertiary_color;
        border-color: $body_text_tertiary_color;

        &:hover {
            background-color: $body_text_tertiary_color;
            color: $body_text_secondary_color;
        }
    }
}

[class*='-element'] {
    &[class*='-widget-button'] {
        .btn {
            &.btn-before-dot {
                &:before {
                    padding-right: 0.21rem;
                }
            }

            &.btn-after-dot {
                &:after {
                    padding-left: 0.21rem;
                }
            }
        }
    }
}

// Button size
.btn-size {
    font-size: $f_size_df;
}

.btn-sm-size {
    font-size: $f_size_sm;
}

.btn-lg-size {
    font-size: $f_size_lg;
}

// Block button
.has-text-color {
    &.has-white-color {
        color: $white !important;
    }
    
    &.has-gray-color {
        color: $gray !important;
    }
    
    &.has-gray-light-color {
        color: $gray-light !important;
    }
    
    &.has-gray-dark-color {
        color: $gray-dark !important;
    }
    
    &.has-blue-color {
        color: $blue !important;
    }
    
    &.has-indigo-color {
        color: $indigo !important;
    }
    
    &.has-purple-color {
        color: $purple !important;
    }
    
    &.has-pink-color {
        color: $pink !important;
    }
    
    &.has-red-color {
        color: $red !important;
    }
    
    &.has-orange-color {
        color: $orange !important;
    }
    
    &.has-yellow-color {
        color: $yellow !important;
    }
    
    &.has-green-color {
        color: $green !important;
    }
    
    &.has-teal-color {
        color: $teal !important;
    }
    
    &.has-cyan-color {
        color: $cyan !important;
    }
}

.has-background {
    &.has-white-background-color {
        background-color: $white !important;
    }
    
    &.has-gray-background-color {
        background-color: $gray !important;
    }
    
    &.has-gray-light-background-color {
        background-color: $gray-light !important;
    }
    
    &.has-gray-dark-background-color {
        background-color: $gray-dark !important;
    }
    
    &.has-blue-background-color {
        background-color: $blue !important;
    }
    
    &.has-indigo-background-color {
        background-color: $indigo !important;
    }
    
    &.has-purple-background-color {
        background-color: $purple !important;
    }
    
    &.has-pink-background-color {
        background-color: $pink !important;
    }
    
    &.has-red-background-color {
        background-color: $red !important;
    }
    
    &.has-orange-background-color {
        background-color: $orange !important;
    }
    
    &.has-yellow-background-color {
        background-color: $yellow !important;
    }
    
    &.has-green-background-color {
        background-color: $green !important;
    }
    
    &.has-teal-background-color {
        background-color: $teal !important;
    }
    
    &.has-cyan-background-color {
        background-color: $cyan !important;
    }
}

// Default
button {
    &:focus {
        outline: 0;
    }
}

/*=============================
// Button group
=============================*/
.btn-group {
    .btn {
        min-width: auto;
    }
}
